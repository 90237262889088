import React, { Fragment, useCallback, useMemo, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { InputField, CheckboxField, HourField } from '../../Form/Fields'
import { USER } from '../../../django'
import {
  transformErrorForForm,
  setNullOnEmptyValues,
  fixInitialValuesNull,
} from '../../Form/Fields/utils'
import moment from 'moment'
import omit from 'lodash/omit'
import ModalDeleteControlloEffettuato from '../ModalDeleteControlloEffettuato'
import dayjs from 'dayjs'
import { range, sumBy } from 'lodash'
import classNames from 'classnames'

function removeControlloEffetuatiFromFormValues(values, id) {
  return {
    ...values,
    controlli_effettuati: values.controlli_effettuati.map(
      (controlloEffettuato) => {
        if (controlloEffettuato.id === id) {
          return {
            ...controlloEffettuato,
            id: null,
            conforme: '',
            note: '',
          }
        }
        return controlloEffettuato
      }
    ),
  }
}

function getPezziConfig(riga, lavorazione) {
  if (lavorazione.attivita_numero === 'ante') {
    return {
      label: 'Ante',
      numero: riga.ante,
      numero_originale: riga.ante,
    }
  }
  if (lavorazione.attivita_numero === 'n_vetri') {
    return {
      numero: parseInt(riga.extra_fields.n_vetri),
      label: 'Vetri',
      numero_originale: parseInt(riga.extra_fields.n_vetri),
    }
  }
  return {
    numero: riga.numero ?? 1,
    label: 'Telai',
    numero_originale: riga.numero ?? 1,
  }
}

const getCheckBoxConfigRiga = function (riga, lavorazione) {
  const item = {
    pezziConfig: getPezziConfig(riga, lavorazione),
    numero_originale: parseInt(getPezziConfig(riga, lavorazione).numero_originale),
  }
  return item
}

const ModalFormAttivitaUpdate = ({
  isOpen,
  toggle,
  save,
  attivita,
  date,
  onDeleteControllo,
  newRecordOfAttivita = false,
}) => {
  const [controlloModalDelete, setControlloModalDelete] = useState(null)
  const isModalDeleteOpen = controlloModalDelete !== null
  const closeControlloModalDelete = useCallback(() => {
    setControlloModalDelete(null)
  }, [])

  const pezziAttivita = useMemo(() => {
    if (attivita) {
      return attivita.pezzi
    }
  }, [attivita])

  const pezziAltreAttivita = useMemo(() => {
    if (attivita) {
      const attivitaStessaLavorazione =
        attivita?.attivita_ordine_lavorazione?.map((attivitaOrdine) => {
          return attivitaOrdine.pezzi
        })
      // return by riga id
      return attivitaStessaLavorazione?.reduce((acc, pezzi) => {
        return {
          ...acc,
          ...pezzi,
        }
      }, {})
    }
  }, [attivita])

  console.log('pezziAltreAttivita', pezziAltreAttivita)

  const initialPezzi = useMemo(() => {
    if (attivita) {
      return attivita?.righe_ordini_lavorazione?.reduce((acc, riga) => {
        const config = getCheckBoxConfigRiga(riga, attivita.lavorazione_data)

        return {
          ...acc,
          [riga.id]: {
            pezzi: range(config.pezziConfig.numero).map((i) => {
              const pezzoAttivita = pezziAttivita[riga.id]?.pezzi[i]
              const pezzoAltreAttivita = pezziAltreAttivita
                ? pezziAltreAttivita[riga.id]?.pezzi[i]
                : false
              return pezzoAttivita || pezzoAltreAttivita || false
            }),
          },
        }
      }, {})
    }
  }, [attivita, pezziAltreAttivita, pezziAttivita])

  console.log('initialPezzi', initialPezzi)

  const numeriPezziInitalChecked = useMemo(() => {
    if (initialPezzi) {
      return Object.keys(initialPezzi).reduce((tot, rigaId) => {
        return (
          tot +
          initialPezzi[rigaId].pezzi.reduce((tot, pezzo) => {
            return tot + (pezzo === true ? 1 : 0)
          }, 0)
        )
      }, 0)
    }
  }, [initialPezzi])

  const totNumeroPezzi = useMemo(() => {
    if (attivita) {
      if (attivita.righe_ordini_lavorazione.length === 0) {
        return 0
      }
      return attivita.righe_ordini_lavorazione.reduce((tot, riga) => {
        return (
          tot +
          getCheckBoxConfigRiga(riga, attivita.lavorazione_data).pezziConfig
            .numero
        )
      }, 0)
    }
  }, [attivita])

  const initialValues = useMemo(() => {
    if (attivita) {
      if (!newRecordOfAttivita) {
        return fixInitialValuesNull({
          ora_inizio: newRecordOfAttivita ? '' : attivita.ora_inizio,
          id: attivita.id,
          ora_fine: attivita.ora_fine,
          note: attivita.note,
          fine_lavorazione: attivita.fine_lavorazione,
          seconda_lavorazione: attivita.seconda_lavorazione,
          pezzi: initialPezzi,
          controlli_effettuati: attivita.ordine
            ? attivita.lavorazione_data.controlli.map((controllo) => ({
                // Flat controllo effettuato as primary data
                id: controllo.controllo_effettuato.id,
                // Real "mandatory" stuff
                conforme: controllo.controllo_effettuato.id
                  ? controllo.controllo_effettuato.conforme
                  : '',
                note: controllo.controllo_effettuato.note,
                // NOTE: We move controllo data because now form
                // data is our "source of truth"
                // (not loving circular stuff)
                controllo: omit(controllo, 'controllo_effettuato'),
                operatore: controllo.controllo_effettuato.operatore_data ?? {},
                // Stable stuff 4 da modal
                initialControlloEffettuato: controllo.controllo_effettuato,
              }))
            : [],
        })
      } else {
        return fixInitialValuesNull({
          ora_inizio: '',
          ora_fine: '',
          note: '',
          fine_lavorazione: false,
          pezzi: initialPezzi,
          seconda_lavorazione: attivita.seconda_lavorazione,
          controlli_effettuati: attivita.ordine
            ? attivita.lavorazione_data.controlli.map((controllo) => ({
                // Flat controllo effettuato as primary data
                id: controllo.controllo_effettuato.id,
                // Real "mandatory" stuff
                conforme: controllo.controllo_effettuato.id
                  ? controllo.controllo_effettuato.conforme
                  : '',
                note: controllo.controllo_effettuato.note,
                // NOTE: We move controllo data because now form
                // data is our "source of truth"
                // (not loving circular stuff)
                controllo: omit(controllo, 'controllo_effettuato'),
                operatore: controllo.controllo_effettuato.operatore_data ?? {},
                // Stable stuff 4 da modal
                initialControlloEffettuato: controllo.controllo_effettuato,
              }))
            : [],
        })
      }
    }
  }, [attivita, initialPezzi, newRecordOfAttivita])

  const [numPezziSelected, setNumPezziSelected] = useState(
    numeriPezziInitalChecked
  )

  const allPezziAreChecked = useMemo(() => {
    return numPezziSelected === totNumeroPezzi
  }, [numPezziSelected, totNumeroPezzi])

  const [showModalAlertPezzi, setShowModalAlertPezzi] = useState(false)
  const toggleModalAlertPezzi = useCallback(() => {
    setShowModalAlertPezzi((show) => !show)
  }, [])

  const [confirmSubmit, setConfirmSubmit] = useState(false)

  // if is a new record of attivita, we need to add the attivita itself
  const attivitaOrdineLavorazione = useMemo(() => {
    if (attivita) {
      if (newRecordOfAttivita) {
        const activities = [
          ...attivita.attivita_ordine_lavorazione,
          {
            data: attivita.data,
            ora_inizio: attivita.ora_inizio,
            ora_fine: attivita.ora_fine,
            note: attivita.note,
            tempo: moment.duration(attivita.tempo).as('minutes') + ' min',
          },
        ]
        return activities.sort((a, b) => {
          if (a.data === b.data) {
            return a.ora_inizio > b.ora_inizio ? 1 : -1
          }
          return a.data > b.data ? 1 : -1
        })
        // return ordered by date and ora_inizio
      }
      return attivita.attivita_ordine_lavorazione
    }
  }, [attivita, newRecordOfAttivita])

  const groupByDate = useMemo(() => {
    if (attivitaOrdineLavorazione) {
      return attivitaOrdineLavorazione.reduce((acc, attivita) => {
        const date = dayjs(attivita.data).format('DD/MM/YYYY')
        if (!acc[date]) {
          acc[date] = []
        }
        acc[date].push(attivita)
        return acc
      }, {})
    }
  }, [attivitaOrdineLavorazione])

  const attivitaOrdineLavorazioneHasOneFineLavorazione = useMemo(() => {
    if (attivita.fine_lavorazione && attivita.ordine) {
      return true
    }
    if (attivitaOrdineLavorazione && attivita.ordine) {
      return attivitaOrdineLavorazione.some((attivita) => {
        return attivita.fine_lavorazione
      })
    } else {
      return false
    }
  }, [attivitaOrdineLavorazione, attivita.fine_lavorazione])

  const AttivitaSchema = Yup.object().shape({
    ora_inizio: Yup.string().required('Campo obbligatorio'),
    // ora_fine: Yup.string().required('Campo obbligatorio'),
    fine_lavorazione: Yup.boolean(),
    controlli_effettuati: Yup.mixed().when(['fine_lavorazione'], {
      is: (fine_lavorazione) => {
        return allPezziAreChecked && numPezziSelected > 0
      },
      then: Yup.array().of(
        Yup.object().shape(
          {
            conforme: Yup.boolean().typeError(
              <div>
                Seleziona se la lavorazione
                <br />è conforme o non conforme.
              </div>
            ),
            note: Yup.string().when('conforme', {
              is: false,
              then: Yup.string().required('Note obbligatorie'),
            }),
          },
          ['conforme']
        )
      ),
      otherwise: Yup.mixed().nullable(),
    }),
  })
  
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl" scrollable>
      <ModalHeader toggle={toggle}>
        {`Modifica attività - ` + moment(date).format('DD/MM/YYYY')}
      </ModalHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={AttivitaSchema}
        onSubmit={(values, actions) => {
          // check if user has selected more pezzi then initial
          if (
            numPezziSelected === numeriPezziInitalChecked &&
            !confirmSubmit &&
            attivita.ordine &&
            values.ora_fine
          ) {
            toggleModalAlertPezzi()
            return actions.setSubmitting(false)
          }
          return save(
            setNullOnEmptyValues({
              ...values,
              new_record: newRecordOfAttivita,
              lavorazione: attivita.lavorazione,
              fine_lavorazione: attivita.ordine
                ? allPezziAreChecked && numPezziSelected > 0
                : values.ora_fine
                ? true
                : false,
              ordine: attivita.ordine,
              controlli_effettuati:
                values.ora_fine && allPezziAreChecked
                  ? values.controlli_effettuati.map((controlloEffettuato) => ({
                      controllo: controlloEffettuato.controllo.id,
                      conforme: controlloEffettuato.conforme,
                      note: controlloEffettuato.note,
                      data: moment(date).format('YYYY-MM-DD'),
                    }))
                  : [],
            })
          ).then(
            () => {
              actions.setSubmitting(false)
            },
            (error) => {
              actions.setSubmitting(false)
              actions.setErrors(transformErrorForForm(error))
            }
          )
        }}
        render={({
          handleSubmit,
          values,
          setValues,
          isSubmitting,
          errors,
          setFieldValue,
        }) => {
          return (
            <>
              <ModalBody style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto', minHeight: '400px' }}>
                <form onSubmit={handleSubmit}>
                  <div className="d-flex justify-content-between">
                    <div>
                      {attivita && attivita.lavorazione_data && (
                        <div className="mt-2">
                          {attivita.seconda_lavorazione_data
                            ? 'Lavorazioni'
                            : 'Lavorazione'}
                          : <strong>{attivita.lavorazione_data.nome}</strong>
                          {attivita && attivita.seconda_lavorazione_data && (
                            <>
                              ,{' '}
                              <strong>
                                {attivita.seconda_lavorazione_data.nome}
                              </strong>
                            </>
                          )}
                        </div>
                      )}
                      {attivita && attivita.ordine_data && (
                        <div className="mb-2">
                          Ordine:{' '}
                          <strong>
                            {attivita.ordine_data.numero_commessa} -{' '}
                            {attivita.ordine_data.cliente.nome}
                          </strong>
                        </div>
                      )}
                    </div>
                    <div className="mt-2">
                      <div>
                        {attivita &&
                          attivita.lavorazione_data &&
                          attivita.righe_ordini_lavorazione.length > 0 && (
                            <div className="d-flex">
                              <div className="mr-2">
                                <strong>
                                  Numero{' '}
                                  {
                                    getCheckBoxConfigRiga(
                                      attivita.righe_ordini_lavorazione[0],
                                      attivita.lavorazione_data
                                    ).pezziConfig.label
                                  }
                                  :{' '}
                                </strong>
                                <span>
                                  {attivita.righe_ordini_lavorazione &&
                                    sumBy(
                                      attivita.righe_ordini_lavorazione,
                                      (riga) => {
                                        return getCheckBoxConfigRiga(
                                          riga,
                                          attivita.lavorazione_data
                                        ).pezziConfig.numero
                                      }
                                    )}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <hr
                    style={{
                      marginBottom: 0,
                    }}
                  />
                  <div className="row">
                    <div className="col-md-8">
                      {attivita.righe_ordini_lavorazione &&
                        attivita.righe_ordini_lavorazione.length > 0 &&
                        attivita.righe_ordini_lavorazione.map((riga, i) => (
                          <div key={i} className="d-flex mt-3">
                            <div className="mr-3">
                              <strong>{i + 1}.</strong>
                            </div>
                            <div>
                              <div>
                                {' '}
                                Tipo serramento: {
                                  riga.tipo_serramento.name
                                }{' '}
                              </div>
                              <div>
                                Codice serramento:{' '}
                                {`${riga.codice_serramento.categoria} ${riga.codice_serramento.forma} ${riga.codice_serramento.codice}`}{' '}
                                - (Numero {riga.numero ?? 1}) -{' ('}
                                {
                                  getCheckBoxConfigRiga(
                                    riga,
                                    attivita.lavorazione_data
                                  ).pezziConfig.numero_originale
                                }{' '}
                                {
                                  getCheckBoxConfigRiga(
                                    riga,
                                    attivita.lavorazione_data
                                  ).pezziConfig.label
                                }
                                {')'}
                              </div>
                              <div>
                                {/* badge for select all pezzi of riga */}
                                {values.ora_fine &&
                                  getCheckBoxConfigRiga(
                                    riga,
                                    attivita.lavorazione_data
                                  ).pezziConfig.numero_originale > 0 && (
                                    <span
                                      className={classNames('badge', {
                                        'badge-primary pointer':
                                          values.ora_fine,
                                        'badge-secondary': !values.ora_fine,
                                      })}
                                      onClick={() => {
                                        if (!values.ora_fine) {
                                          return
                                        }
                                        setNumPezziSelected(
                                          numPezziSelected +
                                            getCheckBoxConfigRiga(
                                              riga,
                                              attivita.lavorazione_data
                                            ).pezziConfig.numero -
                                            values.pezzi[riga.id].pezzi.reduce(
                                              (tot, pezzo) => {
                                                return (
                                                  tot + (pezzo === true ? 1 : 0)
                                                )
                                              },
                                              0
                                            )
                                        )
                                        setFieldValue(
                                          `pezzi[${riga.id}].pezzi`,
                                          range(
                                            getCheckBoxConfigRiga(
                                              riga,
                                              attivita.lavorazione_data
                                            ).pezziConfig.numero
                                          ).map(() => true)
                                        )
                                      }}
                                    >
                                      Seleziona tutti
                                    </span>
                                  )}
                              </div>
                              <div className="d-flex mt-2">
                                {getCheckBoxConfigRiga(
                                  riga,
                                  attivita.lavorazione_data
                                ).pezziConfig.numero_originale > 0 && (
                                  <div>
                                    <div className="mt-2">
                                      {
                                        getCheckBoxConfigRiga(
                                          riga,
                                          attivita.lavorazione_data
                                        ).pezziConfig.label
                                      }
                                    </div>
                                  </div>
                                )}
                                {getCheckBoxConfigRiga(
                                  riga,
                                  attivita.lavorazione_data
                                ).pezziConfig.numero_originale > 0 && (
                                  <div className="ms-3 d-flex flex-wrap">
                                    {/* 
                                  make divs with border and background color 
                                  for divide pezziConfig.numero / riga.numero
                                 */}
                                    {range(riga.numero).map((i) => (
                                      <div
                                        className="d-flex border ml-2 pr-2 mt-2"
                                        key={i}
                                      >
                                        {range(
                                          (i *
                                            getCheckBoxConfigRiga(
                                              riga,
                                              attivita.lavorazione_data
                                            ).pezziConfig.numero) /
                                            riga.numero ?? 1,
                                          (getCheckBoxConfigRiga(
                                            riga,
                                            attivita.lavorazione_data
                                          ).pezziConfig.numero / riga.numero ??
                                            1) *
                                            (i + 1)
                                        ).map((j, numero_check) => (
                                          <span key={j} className="ml-2">
                                            <Field
                                              type="checkbox"
                                              name={`pezzi[${riga.id}].pezzi[${j}]`}
                                              component={CheckboxField}
                                              
                                              onChange={(e) => {

                                                if (e.target.checked) {
                                                  setFieldValue(
                                                    `pezzi[${riga.id}].pezzi[${j}]`,
                                                    true
                                                  )
                                                  setNumPezziSelected(
                                                    numPezziSelected + 1
                                                  )
                                                } else {
                                                  setFieldValue(
                                                    `pezzi[${riga.id}].pezzi[${j}]`,
                                                    false
                                                  )
                                                  setNumPezziSelected(
                                                    numPezziSelected - 1
                                                  )
                                                }
                                              }}
                                              checked={
                                                values.pezzi[riga.id] &&
                                                values.pezzi[riga.id].pezzi[
                                                  j
                                                ] === true
                                              }
                                              className={
                                                (initialPezzi[riga.id] &&
                                                  initialPezzi[riga.id].pezzi[j] ===
                                                    true) ||
                                                !attivita.ora_inizio ||
                                                !values.ora_fine
                                                  ? 'disabled-checkbox'
                                                  : ''
                                              }
                                              label={numero_check + 1}
                                            />
                                          </span>
                                        ))}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="col-md-4 bg-light">
                      <div className="row">
                        {attivita.ordine &&
                          Object.keys(groupByDate).map((date, index) => {
                            return (
                              <Fragment key={index}>
                                <div className="col-md-12 pt-2">
                                  <strong>{date}</strong>
                                </div>
                                <div className="col-md-4 mt-3">
                                  <strong>Ora inizio</strong>
                                </div>
                                <div className="col-md-4 mt-3">
                                  <strong>Ora fine</strong>
                                </div>
                                <div className="col-md-4 mt-3">
                                  <strong>Tempo</strong>
                                </div>
                                {groupByDate[date].map((attivita, index) => {
                                  return (
                                    <Fragment key={index}>
                                      <div className="col-md-4 mt-1">
                                        {attivita.ora_inizio}
                                      </div>
                                      <div className="col-md-4 mt-1">
                                        {attivita.ora_fine}
                                      </div>
                                      <div className="col-md-4 mt-1">
                                        {attivita.tempo}
                                      </div>
                                      {attivita.note && (
                                        <div className="col-md-12 mt-1">
                                          <strong>Note</strong>: {attivita.note}
                                        </div>
                                      )}
                                    </Fragment>
                                  )
                                })}
                              </Fragment>
                            )
                          })}

                        {!attivitaOrdineLavorazioneHasOneFineLavorazione && (
                          <>
                            <div className="col-md-12 mt-4 mb-2">
                              <strong>
                                {dayjs(attivita.data).format('DD/MM/YYYY')}
                              </strong>
                            </div>
                            <div className="col-md-6">
                              <Field
                                label="Ora inizio *"
                                type="text"
                                required
                                popperPlacement="bottom"
                                name="ora_inizio"
                                minTime={
                                  // min foor datepicker
                                  attivitaOrdineLavorazione &&
                                  attivitaOrdineLavorazione.length > 0 &&
                                  attivitaOrdineLavorazione[
                                    attivitaOrdineLavorazione.length - 1
                                  ].data === attivita.data &&
                                  attivitaOrdineLavorazione[
                                    attivitaOrdineLavorazione.length - 1
                                  ].ora_fine
                                    ? attivitaOrdineLavorazione[
                                        attivitaOrdineLavorazione.length - 1
                                      ].ora_fine
                                    : null
                                }
                                component={HourField}
                                className="mb-2 form-control"
                              />
                            </div>
                            {attivita.ora_inizio && !newRecordOfAttivita && (
                              <div className="col-md-6">
                                <Field
                                  label="Ora fine"
                                  type="text"
                                  minTime={values.ora_inizio}
                                  popperPlacement="bottom"
                                  name="ora_fine"
                                  component={HourField}
                                  className="mb-2 form-control"
                                />
                              </div>
                            )}
                            {attivita.ora_inizio && !newRecordOfAttivita && (
                              <div className="col-md-12 mt-2">
                                <Field
                                  label="Note"
                                  type="textarea"
                                  name="note"
                                  component={InputField}
                                  className="mb-2"
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr />
                  {allPezziAreChecked &&
                    (attivita.ora_fine || values.ora_fine) &&
                    attivita &&
                    attivita.ordine &&
                    values.controlli_effettuati.map(
                      (controlloEffettuato, index) => {
                        const operatoreIdLavorazione =
                          controlloEffettuato.operatore.id ?? null
                        const enabled =
                          !operatoreIdLavorazione ||
                          operatoreIdLavorazione === USER.operatore.id
                        return (
                          <div key={index} className="position-relative">
                            <div className="text-center font-weight-bold">
                              Marcatura CE
                            </div>
                            {enabled && controlloEffettuato.id && (
                              <div
                                className="position-absolute"
                                style={{ top: 0, right: 10 }}
                              >
                                <Button
                                  size="sm"
                                  color="danger"
                                  onClick={() => {
                                    setControlloModalDelete(
                                      controlloEffettuato.initialControlloEffettuato
                                    )
                                  }}
                                >
                                  Cancella
                                </Button>
                              </div>
                            )}
                            <div>
                              Descrizione:{' '}
                              <strong>
                                {controlloEffettuato.controllo.descrizione}
                              </strong>
                            </div>
                            <div>
                              Frequenza:{' '}
                              <strong>
                                {controlloEffettuato.controllo.frequenza}
                              </strong>
                            </div>
                            {operatoreIdLavorazione &&
                              operatoreIdLavorazione !== USER.operatore.id && (
                                <div>
                                  Operatore:{' '}
                                  <strong>
                                    {
                                      controlloEffettuato.operatore.user
                                        .first_name
                                    }{' '}
                                    {
                                      controlloEffettuato.operatore.user
                                        .last_name
                                    }
                                  </strong>
                                </div>
                              )}
                            <hr />
                            <Field
                              component={InputField}
                              type={'select'}
                              label={'Conforme'}
                              disabled={!enabled}
                              name={`controlli_effettuati[${index}].conforme`}
                            >
                              <option value="">Non indicato</option>
                              <option value={true}>Conforme</option>
                              <option value={false}>Non Conforme</option>
                            </Field>
                            <Field
                              type="textarea"
                              label="Note"
                              disabled={!enabled}
                              className="mb-2"
                              component={InputField}
                              name={`controlli_effettuati[${index}].note`}
                            />
                          </div>
                        )
                      }
                    )}
                  <Modal
                    isOpen={showModalAlertPezzi}
                    toggle={toggleModalAlertPezzi}
                    size="md"
                    centered
                  >
                    <ModalBody className={'w-100'}>
                      <div className="pt-3 pb-3 w-100 d-flex align-items-center justify-content-center">
                        <i className="fas fa-exclamation-triangle text-danger"></i>
                      </div>
                      Non hai segnalato nessun avanzamento. Sei sicuro di voler
                      continuare?
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggleModalAlertPezzi}>
                        Annulla
                      </Button>
                      <Button
                        color="primary"
                        type={'submit'}
                        onClick={() => {
                          toggleModalAlertPezzi()
                          setConfirmSubmit(true)
                          handleSubmit()
                        }}
                      >
                        Conferma
                      </Button>
                    </ModalFooter>
                  </Modal>
                </form>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-between">
                <Button
                  disabled={isSubmitting}
                  color="secondary"
                  onClick={toggle}
                >
                  Chiudi
                </Button>
                {!attivitaOrdineLavorazioneHasOneFineLavorazione && (
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isSubmitting || (numPezziSelected === 0 && values.ora_fine && attivita.ordine)}
                    color="success"
                  >
                    Salva
                  </Button>
                )}
              </ModalFooter>
              <ModalDeleteControlloEffettuato
                toggle={closeControlloModalDelete}
                isOpen={isModalDeleteOpen}
                controllo={controlloModalDelete}
                onSetEliminato={() => {
                  onDeleteControllo
                    .onSuccess(() => {
                      setValues(
                        removeControlloEffetuatiFromFormValues(
                          values,
                          controlloModalDelete.id
                        )
                      )
                      closeControlloModalDelete()
                    })
                    .run(controlloModalDelete.id)
                }}
              />
            </>
          )
        }}
      />
    </Modal>
  )
}

export default ModalFormAttivitaUpdate
